import $ from "jquery";
$(document).ready(function () {
  $("body")
    .children()
    .each(function () {
      $(this).html(
        $(this)
          .html()
          .replace(/&#8232 ;/g, " ")
      );
    }),
    window.scrollTo(0, 1),
    $(".navbar__burger").click(function () {
      "false" == $(this).attr("aria-expanded")
        ? $(this).attr("aria-expanded", "true")
        : $(this).attr("aria-expanded", "false"),
        $(".navbar__nav, .navbar__burger").toggleClass("is-active");
    }),
    $("a[href*=\\#]:not([href=\\#])").click(function () {
      if (
        location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        location.hostname === this.hostname
      ) {
        var a = $(this.hash);
        (a = a.length ? a : $("[name=" + this.hash.slice(1) + "]")).length &&
          $("html,body").animate({ scrollTop: a.offset().top }, 1e3);
      }
    });

  
});
